<template>
  <LineChart
    :chart-data="data"
    :options="options"
    css-classes="chart-container"
    :style="heigth"
  />
</template>

<script>
import { LineChart } from "vue-chart-3";
import {
  Chart,
  LinearScale,
  LineElement,
  LineController,
  CategoryScale,
  PointElement,
} from "chart.js";
import { color } from "@amcharts/amcharts5";

Chart.register(
  LinearScale,
  LineElement,
  LineController,
  CategoryScale,
  PointElement
);

export default {
  name: "KpiChart",
  components: { LineChart },
  props: {
    labelsValues: Array,
    prop_dataSet: Array,
    prop_options: Object,
  },
  created() {
    this.primaryColor = this.whappyClientConfig.primaryColor;
    this.secondaryColor = this.whappyClientConfig.secondaryColor;

    if (this.prop_options.fontColor) {
      this.fontColor = this.prop_options.fontColor;
    } else {
      this.fontColor = "#303030";
    }

    if (this.prop_options.colorXY) {
      this.colorXY = this.prop_options.colorXY;
    } else {
      this.colorXY = "#303030";
    }
  },
  data() {
    return {
      primaryColor: "",
      secondaryColor: "",
      fontColor: "",
      colorXY: "",
    };
  },
  computed: {
    data() {
      let datasets = [];

      this.prop_dataSet.forEach((element, index) => {
        let chartColor = element.color;

        let arrayDataSet = [];
        element.data.forEach((element) => {
          arrayDataSet.push(element);
        });

        //   if (element.color) {
        //     chartColor = element.color;
        //   } else {
        //     if (index % 2 == 0) {
        //       chartColor = "white";
        //     } else {
        //       chartColor = this.secondaryColor;
        //     }
        //   }

        let objDataSet = {
          label: element.label || false,
          data: arrayDataSet,
          borderColor: chartColor,
          backgroundColor: chartColor,
          fill: {
            target: "origin",
            above: chartColor + "55",
          },
          tension: 0.6,
        };

        datasets.push(objDataSet);
      });

      return {
        labels: this.labelsValues,
        datasets: datasets,
      };
    },
    options() {
      return {
        legend: {
          fontColor: this.fontColor,
        },
        scales: {
          y: {
            beginAtZero: false,
            ticks: {
              color: this.colorXY,
            },
          },
          x: {
            ticks: {
              color: this.colorXY,
            },
          },
          grid: {
            display: false,
          },
        },
        responsive: true,
      };
    },
    heigth() {
      let heigth = null;
      if (this.prop_dataSet[0].label) {
        return { "--heigth": "180px" };
      } else {
        return { "--heigth": "120px" };
      }
    },
  },
};
</script>

<style >
.chart-container canvas {
  width: 100% !important;
  height: var(--heigth) !important;
}
</style>